const state = () => ({
  token: null,
  user: {}
})

const mutations = {
  setToken (state, token) {
    state.token = token
  },
  setUser (state, user) {
    state.user = user
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}