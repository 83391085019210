import qs from "qs"

const Cookie = process.client ? require('js-cookie') : undefined

export default function ({ $axios, store, redirect }) {
  $axios.defaults.paramsSerializer = function(params) {
    return qs.stringify(params)
  }

  $axios.onRequest(config => {
    config.headers.common['Authorization'] = process.env.API_SECRET
    config.headers.common['token'] = `${store.state.member.auth.token}`
    config.headers.common['x-api-key'] = process.env.API_AUTH // AUTH POS
  })

  $axios.onError(error => {
    const code = parseInt(error.response && error.response.status)
    if (code === 400) {
      redirect('/400')
    }

    if (code === 401) {
      // app.$cook.remove('token')
      // app.$cook.remove('user')
      // store.commit('setToken', null)
      // store.commit('setUser', {})
      Cookie.remove('token')
      Cookie.remove('user')

      redirect(process.env.BASE_URL)
    }

    if (code === 500) {
      redirect('/500')
    }
  })
}

